<template>
  <h1>Deres forbrugsvaner</h1>
  <h2>Trin 7/7</h2>
  <div class="answer_field_list">
    <div class="answer_field">
      <h3>Hvordan får de information/nyheder/viden til deres job?</h3>
      <span class="p-float-label">
          <InputText id="howGainInformationForJob" v-model="howGainInformationForJob" type="text"/>
          <label for="howGainInformationForJob">F.eks.: tager de onlinekurser eller går på konferencer?</label>
      </span>
    </div>
    <div class="answer_field">
      <h3>Hvilke sociale netværk bruger de?</h3>
      <div class="social_network_list">
        <SelectButton v-model="personaSocialNetwork" :options="socialNetworkOptions" optionLabel="name" multiple>
          <template #option="slotProps">
            <div>
              <i :class="slotProps.option.icon"></i>
              <label>{{slotProps.option.name}}</label>
            </div>
          </template>
        </SelectButton>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";
export default {
  name: "ConsumptionHabitsQuestions",
  components: {},
  setup() {
    const howGainInformationForJob = computed({
      get: () => store.state.howGainInformationForJob,
      set: val => {
        store.dispatch('setHowGainInformationForJob', {description: val})
      }
    })

    const socialNetworkOptions = computed( () => store.state.socialNetworkOptions )

    let personaSocialNetwork = computed({
      get: () => store.state.personaSocialNetwork,
      set: val => {
        store.dispatch(('setPersonaSocialNetwork'), {social: val})
      }
    })

    return{
      howGainInformationForJob,
      socialNetworkOptions,
      personaSocialNetwork
    }
  }
}
</script>

<style scoped>
.answer_field_list{
  margin-bottom: 325px;
}

::v-deep(.p-buttonset .p-button:first-of-type){
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

::v-deep(.p-selectbutton.p-buttonset .p-button){
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
}

::v-deep(.p-buttonset .p-button:not(:last-child)){
  border-right: 1px solid #ff2a55;
}
</style>